import React, {useState} from "react";

export const UploadLogos: React.FC<{
    visible: boolean,
    setVisible: (visibility: boolean) => void,
    handleLightLogo: (e: React.ChangeEvent<HTMLInputElement>) => void,
    handleDarkLogo: (e: React.ChangeEvent<HTMLInputElement>) => void,
    handleSubmit: () => void}> = ({visible, setVisible, handleLightLogo, handleDarkLogo, handleSubmit}) => {

    const [hasLightLogo, setHasLightLogo] = useState(false);
    const [hasDarkLogo, setHasDarkLogo] = useState(false);

    const changeLightLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHasLightLogo((e.target.files && e.target.files.length > 0) || false);
        handleLightLogo(e);
    }

    const changeDarkLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHasDarkLogo((e.target.files && e.target.files.length > 0) || false);
        handleDarkLogo(e);
    }

    return (
        <section className="newdes-load-logo" style={{display: visible ? "" : "none"}}>
            <div className="newdes-load-logo-block">
                <h4 className="newdes-heading-edit">
                    Загрузите логотип
                </h4>
                <p className="newdes-text-description">
                    Загрузите логотипы для светлой и тёмной версии сайта. Формат SVG или PNG
                    с прозрачным фоном. Для светлой версии загружается полноцветный логотип,
                    для тёмной версии белый монохром.
                </p>
                <div className="newdes-load-logo-flex">
                    <div className="newdes-load-logo-inputs-white">
                        <input type="file" accept={".png, .svg"} id="newdes-logo-loadt-white" className="newdes-logo-load-input" onChange={changeLightLogo}/>
                        <label htmlFor="newdes-logo-loadt-white" className="newdes-logo-load-label">Логотип для
                            светлой версии {hasLightLogo ? "(выбран)" : ""}</label>
                    </div>
                    <div className="newdes-load-logo-inputs-dark">
                        <input type="file" accept={".png, .svg"} id="newdes-logo-loadt-dark" className="newdes-logo-load-input" onChange={changeDarkLogo}/>
                        <label htmlFor="newdes-logo-loadt-dark" className="newdes-logo-load-label">Логотип для
                            темной версии {hasDarkLogo ? "(выбран)" : ""}</label>
                    </div>
                </div>
            </div>
            <div className="newdes-edit-buttons">
                <a className="newdes-btn edit-done" onClick={handleSubmit}>Сохранить</a>
                <a className="newdes-btn edit-cancel" onClick={() => setVisible(false)}>Отмена</a>
            </div>
        </section>
    )
}