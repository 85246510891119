import React, {useEffect, useState} from "react";
import {ILandingBlock} from "../../types/ILandingBlock";
import {ILanding} from "../../types/ILanding";
import * as LandingAPI from "../../requests/LandingAPI";
import {ILandingBlockRequest} from "../../types/ILandingBlockRequest";
import {getBase64} from "../helper/Util";
import {CONFIG} from "../../CONFIG";

export const EditExpertBlock: React.FC<{
    block: ILandingBlock,
    subBlock: ILandingBlock | undefined,
    visible: boolean,
    landing: ILanding | undefined,
    setVisible: (visibility: boolean, block: ILandingBlock | undefined) => void,
    setLanding: (landing: ILanding) => void}> = ({visible, setVisible, block, subBlock, setLanding, landing}) => {

    const [currentBlock, setCurrentBlock] = useState<ILandingBlockRequest>();
    const [preview, setPreview] = useState<string | undefined>();

    useEffect(() => {
        if (subBlock) {
            if (subBlock.mediaList && subBlock.mediaList[0]) {
                setPreview(`${CONFIG.STORAGE_URL}/landing/${landing?.challenge?.id}/media/${subBlock.mediaList[0].localMedia}`)
            }
            setCurrentBlock({...currentBlock,
                id: subBlock.id,
                title: subBlock.title,
                description: subBlock.description,
                landingId: block.landingId,
                parentId: block.id
            })
        } else {
            setCurrentBlock({...currentBlock, title: "", description: "", landingId: block.landingId, parentId: block.id})
        }
    }, [block]);

    const save = () => {
        if (block.landingId && currentBlock && !currentBlock.id) {
            LandingAPI.createBlock(block.landingId, currentBlock, (response) => {
                if (response.status === 200) {
                    LandingAPI.getLanding(response.data.challengeId, (responseL) => {
                        if (responseL.status === 200) {
                            setLanding(responseL.data);
                        }
                    });
                    setVisible(false, undefined);
                }
            })
        } else if (block.landingId && currentBlock && currentBlock.id) {
            LandingAPI.updateBlock(block.landingId, currentBlock, (response) => {
                if (response.status === 200) {
                    setLanding(response.data);
                    setVisible(false, undefined);
                }
            })
        }
    }

    const handleExpertPhoto = function (e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;
        if (!fileList) return;
        if (block) {
            getBase64(fileList[0], ((b64) => {
                setCurrentBlock({...currentBlock, b64: b64});
                setPreview(URL.createObjectURL(fileList[0]));
            }));
        }

    };

    if (visible) {
        return (
            <section className="newdes-edit-block">
                <h4 className="newdes-heading-edit">
                    Название блока
                </h4>
                <form action="" className="newdes-edit-form">
                    <fieldset className="newdes-fieldset">
                        <legend className="newdes-legend">ФИО</legend>
                        <input type="text" className="newdes-edit-input" placeholder="Введите заголовок" value={currentBlock?.title} onChange={(e => setCurrentBlock({...currentBlock, title: e.target.value}))}/>
                    </fieldset>
                    <textarea cols={55} rows={10} className="newdes-edit-input newdes-textarea" placeholder="Укажите необходимую информацию об эксперте, например, его должность, место работы, регалии и достижения" value={currentBlock?.description} onChange={(e => setCurrentBlock({...currentBlock, description: e.target.value}))}></textarea>
                    <div className="newdes-photo-expert">
                        <div className="newdes-photo-expert-block">
                            {preview ?
                                <>
                                    <label htmlFor="newdes-logo-loadt-white" className="newdes-load-expert-photo-plus">
                                        <img src={preview} alt="" className="newdes-photo-expert-loaded"/>
                                    </label>
                                    <p className="newdes-loaded-expert-text">
                                        Нажмите на фото чтобы изменить его
                                    </p>
                                </>
                                :
                                <>
                                    <label htmlFor="newdes-logo-loadt-white" className="newdes-load-expert-photo-plus">
                                        <div className="newdes-gor-line"></div>
                                        <div className="newdes-vert-line"></div>
                                    </label>
                                    <div className="newdes-photo-ezpert-text">
                                        <p className="newdes-load-video-text">
                                            Добавить фото эксперта
                                        </p>
                                        <p className="newdes-text-description">
                                            Максимальное разрешение изображения 512х512px.
                                        </p>
                                    </div>
                                </>
                            }
                            <input type="file" accept={".png, .svg"} id="newdes-logo-loadt-white" className="newdes-logo-load-input" onChange={handleExpertPhoto}/>
                        </div>
                    </div>
                </form>
                <div className="newdes-edit-buttons">
                    <a className="newdes-btn edit-done" onClick={save}>Сохранить</a>
                    <a className="newdes-btn edit-cancel" onClick={() => setVisible(false, undefined)}>Отмена</a>
                </div>
            </section>
        )
    } else {
        return (<></>)
    }

}